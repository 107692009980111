.main-header {
    @include flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: white;
    > .upper, > .lower {
        flex-basis: 100%; }
    > .upper {
        @include flexbox; } }
