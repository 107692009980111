/* Typography */
body {
    font: $font-base;
    color: $font-primary; }

h1, h2, h3 {
    font-weight: $bold; }

h1 {
    font-size: 4rem;
    color: $font-dark;
    margin: 1em 0;
    text-align: center; }

h2 {
    font-size: calc(16px + (100vw / 60));
    margin: 0.75em 0; }

p {
    line-height: 1.5;
    margin: 2em 0; }

ul {
    margin: 2em 0; }

li {
    font-size: 1em;
    padding: 0.5em 0; }

sub, sup {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline; }

sup {
    top: -0.5em;
    margin-left: 1px; }

sub {
    bottom: -0.25em; }

small {
    font-size: 0.9em; }

._text-center {
    text-align: center; }

#main {
    margin-top: 62px; }
