.scroll-top {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    padding: 5px;
    font-size: 2em;
    color: white;
    background-color: $primary-color;
    z-index: 10;
    cursor: pointer;
    &:hover {
        background-color: $secondary-color;
        transition: .2s ease; } }
