html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box; }

*, *:before, *:after {
    box-sizing: inherit; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline; }

body {
    line-height: 1; }

nav ul {
    list-style: none; }

blockquote, q {
    quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none; }

strong {
    font-weight: bold; }

a {
    color: inherit;
    text-decoration: none; }

img {
    max-width: 100%;
    height: auto;
    border: 0; }

table {
    border-collapse: collapse;
    border-spacing: 0; }

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help; }

code {
    display: block; }
