.top-container {
    @include flexbox;
    flex-direction: column;
    min-height: calc(100% - 111px);
    background: url("/static/img/warehouse.jpg") no-repeat center center fixed;
    background-size: cover;
    color: white;
    text-align: center;
    justify-content: center;
    > .heading {
        color: white;
        @media (max-width: 700px) {
            font-size: calc(16px + (100vw / 20)); }
        @media (min-width: 701px) and (max-width: 1200px) {
            font-size: calc(10px + (100vw / 20)); }
        @media (min-width: 1201px) and (max-width: 1500px) {
            font-size: calc(5px + (100vw / 20)); } }
    > .subheading {
        font-size: 1.5rem;
        margin: 0;
        padding: 0 5em;
        @media (max-width: 900px) {
            padding: 0 3em; }
        @media (max-width: 700px) {
            font-size: 1.2rem;
            padding: 0 1em; }
        p:first-of-type {
            margin-top: 0; } }
    > .button {
        display: inline-block;
        padding: 1em 2em;
        margin: 0 auto 3em;
        border: 2px solid white;
        font-weight: $bold;
        z-index: 6;
        &:hover {
            background-color: white;
            color: #333333;
            transition: .5s; } }
    > .button p {
        margin: 0;
        padding: 0;
        line-height: 1; }
    @media (max-width: 700px) {
        min-height: calc(100vh - 62px);
        height: 100%; } }
