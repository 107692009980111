.alert-message {
    padding: 0 1rem;
    border: 1px solid white;
    margin-bottom: 5px;
    width: 100%;
    text-align: center; }
.alert-success {
    color: #155724;
    background: #d4edda;
    border-color: #c3e6cb; }
.alert-warning {
    color: #856404;
    background: #fff3cd;
    border-color: #ffeeba; }
.close {
    padding: 10px 15px;
    margin: 0 10px;
    font-size: 1.5rem;
    background: none;
    border: none;
    opacity: 0.5;
    &:hover {
        cursor: pointer;
        opacity: 1; } }

