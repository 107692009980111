$font-base: 18px/1 'Open Sans', Arial, sans-serif;
$font-primary: #333;
$font-dark: #111;
$primary-color: rgba(14, 139, 200,1);
$secondary-color: rgba(212, 63, 58, 1);
$light-gray: rgba(230,230,230,1);
$bold: 600;
$bolder: 700;
$boldest: 800;
$light: 200;
