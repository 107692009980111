.main-heading {
    @include flexbox;
    flex-direction: column;
    opacity: 0.8;
    padding-top: 110px;
    margin-top: -110px;
    z-index: 1;
    @media (max-width: 700px) {
        padding-top: 61px;
        margin-top: -61px; }
    > h1 {
        color: white;
        @media (max-width: 700px) {
            font-size: calc(16px + (100vw / 20)); }
        @media (min-width: 701px) and (max-width: 1200px) {
            font-size: calc(10px + (100vw / 20)); }
        @media (min-width: 1201px) and (max-width: 1500px) {
            font-size: calc(5px + (100vw / 20)); } }
    &.-warehousing {
        background: url("/static/img/warehouse.jpg") no-repeat center center fixed;
        @media (max-width: 700px) {
            background-attachment: scroll; } }
    &.-delivery {
        background: url("/static/img/delivery.jpg") no-repeat center center fixed;
        @media (max-width: 700px) {
            background-attachment: scroll; } }
    &.-contact {
        background: url("/static/img/boxes.jpg") no-repeat center center fixed;
        @media (max-width: 700px) {
            background-attachment: scroll; } }
    &.-warehousing, &.-delivery, &.-contact {
        background-size: cover;
        -webkit-background-clip: content-box;
        background-clip: content-box; } }
