.main-logo {
    padding: 5px 10px;
    @include flexbox;
    align-items: center;
    > .logo {
        max-width: 150px;
        min-width: 150px;
        margin-left: 10px;
        @media (max-width: 700px) {
            margin-left: 5px; } } }
