.main-menu {
    @include flexbox;
    align-items: center;
    justify-content: center;
    @media (max-width:700px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: $primary-color;
        z-index: -1;
        opacity: 0;
        flex-direction: column; }
    > .item {
        font-size: 1.2rem;
        flex-basis: 100%;
        background-color: $primary-color;
        padding: 0.75em 0;
        text-align: center;
        color: white;
        font-weight: $bold;
        border-right: 2px solid white;
        outline-color: rgba(255,0,0,1);
        &:last-child {
            border-right: 0; }
        &:hover {
            transition: .5s ease;
            background-color: white;
            color: $primary-color; }
        @media (max-width:700px) {
            width: 100%;
            text-align: right;
            padding: 1em 1.5em;
            border-top: 1px solid white;
            border-right: 0;
            opacity: 0;
            transform: translateY(-20px);
            transition: .2s ease;
            display: none;
            &:nth-child(1) {
                margin-top: 75px; } } } }

.hidden {
    display: none; }

.nav-open {
    @media (max-width: 700px) {
        position: absolute;
        right: 20px;
        top: 15px;
        display: block;
        width: 40px;
        height: 33px;
        cursor: pointer;
        z-index: 9999;
        > span {
            display: block;
            width: 40px;
            height: 7px;
            background-color: $primary-color;
            &:nth-child(2) {
                margin-top: 6px;
                opacity: 1; }
            &:nth-child(3) {
                margin-top: 6px; } } } }

#nav:checked + .nav-open {
    @media (max-width: 700px) {
        transform: rotate(45deg);
        > span {
            background: white;
            transition: transform .2s ease;
            &:nth-child(1) {
                transform: translateY(13px) rotate(180deg); }
            &:nth-child(2) {
                opacity: 0; }
            &:nth-child(3) {
                transform: translateY(-13px) rotate(90deg); } } } }

#nav:checked ~ .main-menu {
    @media (max-width: 700px) {
        z-index: 9990;
        opacity: 1;
        > .item {
            opacity: 1;
            transform: translateY(0);
            display: block; } } }

