.main-footer {
    @include flexbox;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    background-color: $light-gray;
    height: 80px;
    text-align: center;
    @media (max-width: 700px) {
        height: 60px; } }
