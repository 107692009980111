.contact-form {
    @include flexbox;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    > .input, > .textarea, > .button {
        margin: 20px 10px;
        border-radius: 3px;
        padding: 10px 5px;
        font-size: 1em;
        border: 2px solid $primary-color;
        min-height: 40px;
        outline-color: rgba(255,0,0,1); }
    > .button {
        width: 100px;
        margin: 20px auto;
        color: $primary-color;
        font-weight: $bold;
        background: none;
        cursor: pointer;
        &:hover {
            background: $primary-color;
            color: white;
            transition: .5s ease; } }
    > .textarea {
        font: $font-base;
        resize: vertical;
        overflow: auto;
        min-height: 100px;
        @media (max-width: 700px) {
            min-height: 150px; } } }
