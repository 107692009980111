.header-contact {
    flex-basis: 100%;
    @include flexbox;
    justify-content: flex-end;
    align-items: center;
    margin-right: 50px;
    @media (max-width: 800px) {
        margin-right: 10px; }
    @media (max-width: 700px) {
        display: none; }
    > .item {
        @include flexbox;
        justify-content: space-around;
        align-items: center;
        margin-left: 20px;
        .icon {
            max-width: 40px;
            max-height: 40px;
            @media (max-width: 700px) {
                display: none; }
            > svg {
                height: 40px;
                width: 40px;
                fill: #444; } }
        .contact-text {
            @include flexbox;
            flex-direction: column;
            @media (max-width: 750px) {
                font-size: 16px; }
            > .option {
                margin-left: 10px;
                p {
                    margin: 0;
                    padding: 0; }
                &.-red {
                    color: $secondary-color;
                    font-weight: bold;
                    margin-top: 5px; } } } } }
