.info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 1em;
    z-index: 5;
    @media (max-width: 700px) {
        flex-direction: column;
        flex-wrap: nowrap; }
    @media (max-width: 600px) {
        padding: 2em 1em; }
    &.-gray {
        background-color: rgba(247,247,247,1); }
    > .left, > .right {
        flex-grow: 1;
        flex-basis: 50%;
        text-align: center;
        width: 100%;
        @media (max-width: 700px) {
            flex-basis: auto; }
        ul {
           list-style: none;
           margin-bottom: 0; }
        > .highlighted {
            display: block;
            font-weight: $bold;
            font-size: 1.25rem;
            color: $secondary-color;
            margin: 1em 0;
            @media (max-width: 400px) {
                font-size: 1rem; }
            p {
                margin: 0;
                padding: 0; } } }
    > .left, > .right {
        &.-first, {
            @media (max-width: 700px) {
                @include order(1); } }
        &.-second {
            @media (max-width: 700px) {
                @include order(2); } }
        &.-display-none {
            @media (max-width: 700px) {
                display: none; } } } }
